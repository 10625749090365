<template>
  <div class="uk-container uk-margin-medium-top">
    <div class="uk-card uk-card-default uk-card-body">
      <h2>My Notifications</h2>
      <p>There are no notifications.</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>